<template>
    <container>>
      <div slot="right_box_content">
        <component :is="curRightComponent"  ref="right"></component>
      </div>
      <div slot="bottom_box_content" style="padding:10px 10px;">
        <component :is="curBottomComponent"></component>
      </div>
    </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    // Right: () => import('./components/Right'),
    Left: () => null,
    Right: () => null
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    this.setRightComponent('Right')
  }
}
</script>
